import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../containers/Seo"
import Container from "../components/Container"
import PostArchive from "../containers/PostArchive"
import { responsive } from "../contexts/responsive"
import { Box, Stack, Text } from "@chakra-ui/react"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Container py="2em">
        <Seo title="特輯列表" />
        <p>
          目前沒有文章喔
        </p>
      </Container>
    )
  }

  return (
    <>
      <Seo title="特輯列表" />
      <Container maxWidth="unset" px={responsive('2.25em', '7.5vw')} my={responsive('2em', '4em')}>
        <Stack spacing={'0.5em'} letterSpacing={'1.8px'} textAlign={'center'} mb={responsive('1.875em', '6em')}>
          <Text fontWeight={'bold'} fontSize={responsive('1.75em', '3.75em')}>特輯列表</Text>
          <Text color="custom.textGray" fontSize={responsive('0.875em', '1.875em')}>透過我們的一字一句，讓你感受不同主題領域的魅力與重要性！</Text>
        </Stack>
        <Box pt={responsive('1.375em', 0)} borderTop={responsive('2.5px solid', 'none')} color="custom.bgGray" >
          <Text letterSpacing={'1.8px'} fontSize={responsive('1.125em', '2.25em')}>所有文章特輯</Text>
        </Box>
        <PostArchive posts={posts} />

        {previousPagePath && (
          <>
            <Link to={previousPagePath}>上一頁</Link>
            <br />
          </>
        )}
        {nextPagePath && <Link to={nextPagePath}>下一頁</Link>}
      </Container>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "YYYY-MM-DD")
        title
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 1.33
                )
              }
            }
          }
        }
      }
    }
  }
`
