import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import parse from "html-react-parser"

import Link from '../components/Link'
import { responsive } from '../contexts/responsive'

// as={`ol`} style={{ listStyle: `none` }}

const PostArchive = ({ posts, ...props }) => {
  return (
    <Flex flexWrap="wrap" mx={responsive(0, '-1.5vw')} mt="0.5em" {...props}>
      {posts.map((post, i) => {
        const title = post.title
        const featuredImage = {
          image: getImage(post.featuredImage?.node?.localFile),
          alt: post.featuredImage?.node?.alt || ``,
        }
        // console.log(parse('<p>我是大帥哥'))
        return (
          <Box width={responsive('100%', 1 / 3)} px={responsive('0', '1.5vw')} mt={responsive(i && '1.875em', (i / 3) >= 1 ? '4.375em' : 0)} key={post.uri}>
            <Stack
              as="article"
              itemScope
              itemType="http://schema.org/Article"
              pb={responsive('1em', '2em')}
              border="3px solid black"
              spacing="1em"
              letterSpacing={'1.8px'}
              height="100%"
            >
              <Stack as="header" spacing={responsive('1em', '1.625em')}>
                <Box borderBottom="3px solid black">
                  {featuredImage?.image && (
                    <Link to={post.uri} itemProp="url">
                      <GatsbyImage {...featuredImage} />
                    </Link>
                  )}
                </Box>
                <Box px={responsive('1.25em', '1.375em')}>
                  <Heading fontWeight={500} fontSize={responsive('1.25em', '1.75em')}>
                    <Link to={post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title.substring(0, 30))}</span>
                    </Link>
                  </Heading>
                  <Box pt="0.875em" mt={responsive('1.125em', '2em')} borderTop="1.5px solid" color="custom.textGray">
                    <Text fontSize={responsive('1em', '1.375em')}>
                      <section itemProp="description">{parse(post.excerpt.substring(0, 50) + '...')}</section>
                    </Text>
                  </Box>
                </Box>
                {/* <small>By {post.author?.node?.name} | {post.date}</small> */}
              </Stack>
            </Stack>
          </Box>
        )
      })}
    </Flex>
  )
}

export default PostArchive
